import React from "react";
import * as s from "./styles";

const BigTask = ({ url, title, color, children }) => {
  return (
    <s.BigTaskWrapper>
      <s.BigTaskContainer>
        <s.TaskImage src={url} />
        <s.TitleTask>{title}</s.TitleTask>
        <s.TaskDescription>{children}</s.TaskDescription>
      </s.BigTaskContainer>
      <s.Color color={color} />
    </s.BigTaskWrapper>
  );
};
export default BigTask;
