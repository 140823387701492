const Theme = {
  colors: {
    black: "#1f1f1f",
    darkGrey: "#919399",
    grey: "#ceced2",
    highlights: "#efeff3",
    white: "#f8f8f8",
    rose: "#ef325f",
    violet: "#b43dec",
    blue: "#1fb6ff",
    yellow: "#1fb6ff",
    background: "#252532",
  },
  mediaQueries: {
    smallest: `only screen and (max-width: 500px)`,
    smallLaptop: `only screen and (max-width: 600px)`,
    small: `only screen and (max-width: 800px)`,
    medium: `only screen and (max-width: 1000px)`,
    large: `only screen and (max-width: 1200px)`,
  },
};

export default Theme;
