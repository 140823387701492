import React from "react";
import * as s from "./styles";

const Task = ({url, children }) => {
  return (
    <s.TaskWrapper>
      <s.TaskImage src={url} />
      <s.TaskText>{children}</s.TaskText>
    </s.TaskWrapper>
  );
};
export default Task;
