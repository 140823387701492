import { createGlobalStyle } from "styled-components";

import GilroyRegularWoff2 from "../fonts/Gilroy-Regular.woff2";
import GilroyRegularWoff from "../fonts/Gilroy-Regular.woff";
import GilroyRegularEot from "../fonts/Gilroy-Regular.eot";

import GilroyBoldWoff2 from "../fonts/Gilroy-Bold.woff2";
import GilroyBoldWoff from "../fonts/Gilroy-Bold.woff";
import GilroyBoldEot from "../fonts/Gilroy-Bold.eot";

export default createGlobalStyle`

@font-face {
        font-family: 'Gilroy-Regular';
        src: local('Gilroy-Regular'), local('Gilroy-Regular'),
        url(${GilroyRegularWoff2}) format('woff2'),
        url(${GilroyRegularWoff}) format('woff'),
        url(${GilroyRegularEot}) format('eot');
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy-Bold';
        src: local('Gilroy-Bold'), local('Gilroy-Bold'),
        url(${GilroyBoldWoff2}) format('woff2'),
        url(${GilroyBoldWoff}) format('woff'),
        url(${GilroyBoldEot}) format('eot');
        font-style: normal;
    }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;


  }
  body {
    font-family: 'Gilroy-Regular';
    font-weight: 400;
    line-height: 1.6;
  }
  a, input, textarea, button {
    outline: none;
    text-decoration: none;
  }
`;
