const resources = {
  en: {
    translation: {
      "Challenge before wish": "Challenge before wish",
      "Header description":
        "Share domestic tasks with your soul mate and have fun",
      "Ipoteko is a game for couples": "Ipoteko is a game for couples",
      Download: "Download on the",
      "First description":
        "It helps to share domestic tasks and make it sparkle.No more fighting about dirty dishes and socks on the floor.",
      RU: "RU",
      EN: "EN",
      "First step": "Compete with your partner in domestic chores.",
      "Second step": "Get ipo-coins.",
      "Third step": "Pick a wish. And make the world go away.",

      "Update title": "New challenges are coming soon!",
      "Update description":
        "Tasks will be more difficult and fascinating, wishes will be more piquant. Only the most courageous couples can do it.",
      "First addition": "Hot challenges for every day",
      "Second addition": "Additional ways of getting ipo-coins",
      "Third addition": "Passionate, ardent wishes",

      "First goal title": "Take up the challenge",
      "First goal description":
        "Pick a task you like. Complete it and request approval from your partner.",
      "Second goal title": "Earn coins",
      "Second goal description":
        "Every completed task has its price. The more challenging the task is, the more coins you get.",
      "Third goal title": "Fulfill your wish",
      "Third goal description":
        "Exchange earned coins for wishes. Do you want more fun? Keep completing tasks and earn coins",
      "Footer title": "Compete and strengthen relations with Ipoteko",
      "Privacy Policy": "Privacy Policy",
    },
  },
  ru: {
    translation: {
      "Challenge before wish": "Принял вызов - выбирай желание",
      "Header description":
        "Распределяйте домашние обязанности со своей второй половинкой весело и без ссор",
      "Ipoteko is a game for couples": "Ipoteko - игра для пар",
      Download: "Загрузите на",
      "First description":
        "С помощью приложения можно распределить домашние обязанности весело и с огоньком. Больше никаких ссор из-за грязной посуды и разбросанных носков.",
      RU: "RU",
      EN: "EN",
      "First step": "Соревнуйтесь с партнером в выполнении рутинных дел. ",
      "Second step": "Получайте ipo-монеты. ",
      "Third step": "Выбирайте желание. И пусть весь мир подождет.",

      "Update title": "Новые вызовы совсем скоро!",
      "Update description":
        "Для самых смелых пар еще больше сложных и увлекательных заданий, пикантных желаний.",
      "First addition": "“Горячие” испытания на каждый день",
      "Second addition": "Дополнительные способы получения ipo-монет.",
      "Third addition": "Страстные, пылкие желания",

      "First goal title": "Принимайте вызовы",
      "First goal description":
        "Выбирайте задание, которое вам нравится. Выполняйте его и отправляйте на одобрение партнеру.",
      "Second goal title": "Зарабатывайте монеты",
      "Second goal description":
        "За каждое выполненное задание начисляется определеное количество монет. Чем сложнее задание, тем больше монет вы за него получите",
      "Third goal title": "Исполняйте свои желания",
      "Third goal description":
        "Обменивайте накопленные монеты на желания. Хотите больше удовольствий - продолжайте выполнять задания и зарабатывать монеты.",
        "Footer title": "Соревнуйтесь и укрепляйте отношения вместе с Ipoteko",
        "Privacy Policy": "Политика конфиденциальности",
    },
  },
};

export default resources;
