import styled from "styled-components";

export const BigTaskWrapper = styled.div`
  display: flex;
  width: 30%;
  min-width: 350px;
  /* flex-basis: 390px; */
  height: 320px;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px;

  /* @media ${(props) => props.theme.mediaQueries.large} {
    width: 290px;
  } */

  @media ${(props) => props.theme.mediaQueries.smallest} {
    width: 100%;
    margin: 0px 0px;
    min-width: 250px;
    margin-top: 56px;
  }
`;

export const BigTaskContainer = styled.div`
  height: 304px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #313141;
  border-radius: 16px;
  padding: 30px;
`;

export const TaskImage = styled.img`
  width: 72px;
`;

export const TitleTask = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-family: Gilroy-Bold;
  color: #f8f8f8;
  text-align: center;
  margin-top: 30px;
 
`;

export const TaskDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
`;

export const Color = styled.div`
  width: 330px;
  height: 16px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background: ${(props) => props.color};
  @media ${(props) => props.theme.mediaQueries.large} {
    width: 240px;
  }
`;
