import styled from "styled-components";

export const TaskWrapper = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const TaskImage = styled.img`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

export const TaskText = styled.div`
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
  max-width: 470px;
  display: flex;
  align-items: center;
  @media ${(props) => props.theme.mediaQueries.smallest} {
   font-size: 16px;
  }
`;
