import React from "react";
import * as s from "./styles";
import googlePlayIcon from "./assets/googlePlayIcon.svg";
import appleIcon from "./assets/appleIcon.svg";

import appStoreText from "./assets/appStoreText.svg";
import googlePlayText from "./assets/googlePlayText.svg";

import { useTranslation } from "react-i18next";

const ButtonsDownload = ({ column = false }) => {
  const { t } = useTranslation();
  return (
    <s.ButtonsDownload column={column}>

      <s.ButtonDownloadWrapper column={column}>
        <s.LogoPlatforn column={column} src={appleIcon} />
        <s.DownloadTextWrapper column={column}>
          <s.DownloadText column={column}>{t("Download")}</s.DownloadText>
          <s.DownloadPlatform column={column} src={appStoreText} />
        </s.DownloadTextWrapper>
      </s.ButtonDownloadWrapper>

      <s.ButtonDownloadWrapper column={column}>
        <s.LogoPlatforn column={column} src={googlePlayIcon} />
        <s.DownloadTextWrapper column={column}>
          <s.DownloadText column={column}>{t("Download")}</s.DownloadText>
          <s.DownloadPlatform column={column} src={googlePlayText} />
        </s.DownloadTextWrapper>
      </s.ButtonDownloadWrapper>
    </s.ButtonsDownload>
  );
};

export default ButtonsDownload;
