import styled from "styled-components";
import background2 from "./assets/background3.svg";
import backgroundMedium from "./assets/backgroundMedium.svg";
import backgroundFooter from "./assets/backgroundFooter.svg";
import bgFooterPhone from "./assets/bgFooterPhone.svg";
export const MainContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 100%;

  @media ${(props) => props.theme.mediaQueries.large} {
    padding: 0 60px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    padding: 0 15px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.background};
  background-image: url(${background2}), url(${backgroundFooter});
  background-position: top, bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 30px;

  @media ${(props) => props.theme.mediaQueries.large} {
    background: ${(props) => props.theme.colors.background};
    background-image: url(${backgroundMedium}), url(${backgroundFooter});
    background-position: top, bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    background: ${(props) => props.theme.colors.background};

    background-image: url(${backgroundFooter});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    background: ${(props) => props.theme.colors.background};
    background-image: url(${bgFooterPhone});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 0;
  }
`;
export const HeaderText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.mediaQueries.medium} {
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
`;

export const HeaderImageWrapper = styled.div`
  position: relative;
  @media ${(props) => props.theme.mediaQueries.medium} {
    display: flex;
    justify-content: center;
    margin-top: 150px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
`;

export const PhonesHeader = styled.img`
  width: 560px;
`;

export const LanguageWrapper = styled.div`
  color: white;
  margin-top: 80px;
  display: flex;
  & :first-child {
    margin-right: 40px;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-top: 60px;
  }

  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 63px;
  }
`;

export const LogoApp = styled.img`
  margin-top: 165px;
  width: 350px;

  @media ${(props) => props.theme.mediaQueries.small} {
    width: 230px;
    margin-top: 60px;
  }

  @media ${(props) => props.theme.mediaQueries.smallest} {
    width: 230px;
    margin-top: 58px;
  }
`;

export const DescriptionApp = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 14px;
  color: rgba(255, 255, 255, 0.6);
  max-width: 500px;
  @media ${(props) => props.theme.mediaQueries.medium} {
    text-align: center;
  }
`;

export const LinesHeader = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 1024px;
`;

export const Language = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-family: Gilroy-Bold;
  color: ${(props) =>
    props.selected ? "#F8F8F8" : "rgba(248, 248, 248, 0.3)"};
  cursor: pointer;
`;

export const PhoneHeader = styled.img`
  position: absolute;
  right: 220px;
  top: -20px;
  width: 320px;
  transform: rotate(5deg);
  opacity: 0.5;
  z-index: 3;
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.4));
  @media ${(props) => props.theme.mediaQueries.large} {
    display: none;
  }
`;
export const PhoneHeaderMain = styled.img`
  position: absolute;
  right: 105px;
  top: 125px;
  width: 310px;
  transform: rotate(5deg);
  z-index: 5;
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.4));
  @media ${(props) => props.theme.mediaQueries.large} {
    right: 35px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    position: static;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    position: static;
    width: 75%;
    top: 0px;
  }
`;

export const HeaderMobilePhotoWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 200px;
`;

export const HeaderMobilePhoto = styled.img`
  width: 500px;
`;

export const Title2 = styled.div`
  font-style: normal;
  font-family: Gilroy-Bold;
  font-size: 44px;
  line-height: 56px;
  color: #f8f8f8;
  max-width: 480px;
  margin-top: 44px;
  @media ${(props) => props.theme.mediaQueries.medium} {
    text-align: center;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    text-align: center;
    font-size: 32px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    text-align: center;
    font-size: 34px;
  }
`;

export const ButtonsDownloadHeader = styled.div`
  margin-top: 65px;
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-top: 50px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 50px;
  }
`;

export const TasksDescription = styled.div`
  margin-top: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column-reverse;
  }
`;

export const BodyWrapper = styled.div``;

export const ImageWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 800px;

  @media ${(props) => props.theme.mediaQueries.medium} {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    position: static;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-top: 30px;
    height: 580px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    height: auto;
    margin-top: 60px;
  }
`;

export const LinesBodyFirst = styled.img`
  position: absolute;
  width: 600px;
  margin-top: 100px;
  @media ${(props) => props.theme.mediaQueries.large} {
    width: 500px;
    margin-left: -150px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: 0px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 95%;
    margin-top: 10px;
  }
`;

export const PhoneBodyFirst = styled.img`
  position: absolute;
  width: 320px;
  margin-left: 180px;
  margin-top: 30px;
  z-index: 10;
  transform: rotate(5deg);
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.4));
  @media ${(props) => props.theme.mediaQueries.large} {
    width: 300px;
    margin-left: 10px;
    margin-top: 50px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 120%;
    position: static;
    margin-top: 0px;
  }
`;

export const LinesBodySecond = styled.img`
  position: absolute;
  width: 600px;
  margin-top: 100px;
  @media ${(props) => props.theme.mediaQueries.large} {
    width: 500px;
    right: -90px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 95%;
    margin-top: 40px;
  }
`;

export const PhoneBodySecond = styled.img`
  position: absolute;
  width: 320px;
  margin-left: 180px;
  margin-top: 30px;
  z-index: 10;
  transform: rotate(5deg);
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.4));
  @media ${(props) => props.theme.mediaQueries.large} {
    width: 300px;
    right: 0;
    margin-top: 50px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 120%;
    position: static;
    margin-top: 0px;
  }
`;

export const CardRowImage = styled.img`
  position: absolute;
  z-index: 20;
  margin-top: 470px;
  right: 0;
  margin-right: 270px;
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.5));
  transform: rotate(5deg);
  @media ${(props) => props.theme.mediaQueries.large} {
    margin-right: 40px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-left: auto;
    margin-right: auto;
    left: -10%;
    right: 0;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    left: 0;
    width: 85%;
    margin-top: 72%;
  }
`;
export const CardCoinImage = styled.img`
  position: absolute;
  z-index: 20;
  margin-top: 350px;
  right: 50px;
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.5));
  transform: rotate(5deg);
  @media ${(props) => props.theme.mediaQueries.large} {
    right: -10px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: auto;
    margin-right: auto;
    left: 260px;
    right: 0;
  }
  @media ${(props) => props.theme.mediaQueries.small} {
    margin-left: auto;
    margin-right: auto;
    left: 70%;
    right: 0;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-left: auto;
    margin-right: auto;
    left: 70%;
    width: 25%;
    margin-top: 48%;
  }
`;
export const CardLevelImage = styled.img`
  position: absolute;
  z-index: 20;
  right: 50px;
  top: 105px;
  transform: rotate(5deg);
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.5));
  @media ${(props) => props.theme.mediaQueries.large} {
    right: -10px;
  }
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: auto;
    margin-right: auto;
    left: 350px;
    top: -20px;
    right: 0;
  }

  @media ${(props) => props.theme.mediaQueries.smallest} {
    left: 83%;
    width: 13%;
    margin-top: 2%;
  }
`;

export const TasksList = styled.div`
  margin-top: 40px;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 55px;
  }
`;

export const TasksAppWrapper = styled.div``;

export const UpdateDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column;
  }
`;

export const LogoWithMotto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoAppImage = styled.img`
  margin-top: 200px;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 160px;
  }
`;

export const MottoApp = styled.div`
  text-align: center;
  color: #f8f8f8;
  font-size: 32px;
  line-height: 50px;
  margin-top: 28px;
`;

export const StartsWrapper = styled.div``;

export const BigTasksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-top: 200px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 70px;
  }
`;

export const ButtonsDownloadFotter = styled.div`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 40px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 180px;
  margin-bottom: 80px;

  @media ${(props) => props.theme.mediaQueries.smallest} {
    flex-direction: column;
    align-items: center;
  }
`;

export const DateWithNameApp = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  line-height: 24px;
`;

export const PrivacyPolicy = styled.div`
  text-decoration-line: underline;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    margin-top: 16px;
  }
`;
