import React, { useState } from "react";
import logoIpoteko from "./assets/logoIpoteko.svg";

import linesBodyFirst from "./assets/linesBodyFirst.svg";
import medal from "./assets/medal.svg";
import moneyBag from "./assets/moneyBag.svg";
import starTask from "./assets/starTask.svg";
import linesBodySecond from "./assets/linesBodySecond.svg";
import starBigTask from "./assets/starBigTask.svg";

import cardCoin from "./assets/cardCoin.svg";
import cardLevel from "./assets/cardlevel.svg";
import cardRow from "./assets/test.svg";

import fire from "./assets/fire.svg";
import okey from "./assets/okey.svg";
import heart from "./assets/heart.svg";
import boxingGloves from "./assets/boxingGloves.svg";
import logoApp from "./assets/logoApp.svg";
import coins from "./assets/coins.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import * as s from "./styles";
import Task from "../components/Task";
import BigTask from "../components/BigTask";
import ButtonsDownload from "../components/ButtonsDownload";

import phoneBodyEng1 from "./assetsEng/phoneBodyEng1.svg";
import phoneBodyEng2 from "./assetsEng/phoneBodyEng2.svg";
import phoneHeaderEng from "./assetsEng/phoneHeaderEng.svg";
import phoneHeaderEngMain from "./assetsEng/phoneHeaderEngMain.svg";
import cardRowEng from "./assetsEng/cardRowEng.svg";

import phoneBodyRus1 from "./assetsRus/phoneBodyRus1.svg";
import phoneBodyRus2 from "./assetsRus/phoneBodyRus2.svg";
import phoneHeaderRus from "./assetsRus/phoneHeaderRus.svg";
import phoneHeaderRusMain from "./assetsRus/phoneHeaderRusMain.svg";

const language = {
  RU: "ru",
  EN: "en",
};

const MainPage = () => {
  const [currLang, setCurrLang] = useState(language.EN);
  const { t } = useTranslation();

  const handleSwitchLang = (lang) => {
    setCurrLang(lang);
    i18next.changeLanguage(lang);
  };

  return (
    <s.MainWrapper>
      <s.MainContainer>
        <s.HeaderWrapper>
          <s.HeaderText>
            <s.LanguageWrapper>
              <s.Language
                selected={currLang === language.EN}
                onClick={() => handleSwitchLang(language.EN)}
              >
                {t("EN")}
              </s.Language>
              <s.Language
                selected={currLang === language.RU}
                onClick={() => handleSwitchLang(language.RU)}
              >
                {t("RU")}
              </s.Language>
            </s.LanguageWrapper>
            <s.LogoApp src={logoIpoteko} />
            <s.Title2>{t("Challenge before wish")}</s.Title2>
            <s.DescriptionApp>{t("Header description")}</s.DescriptionApp>

            <s.ButtonsDownloadHeader>
              <ButtonsDownload />
            </s.ButtonsDownloadHeader>
          </s.HeaderText>

          <s.HeaderImageWrapper>
            <s.PhoneHeader
              src={currLang === language.EN ? phoneHeaderEngMain : phoneHeaderRusMain}
            />
            <s.PhoneHeaderMain
              src={currLang === language.EN ? phoneHeaderEng : phoneHeaderRus }
            />
            <s.CardRowImage src={currLang === language.EN ? cardRowEng : cardRow} />
            <s.CardCoinImage src={cardCoin} />
            <s.CardLevelImage src={cardLevel} />
          </s.HeaderImageWrapper>
        </s.HeaderWrapper>

        <s.BodyWrapper>
          <s.TasksDescription>
            <s.ImageWrapper>
              <s.LinesBodyFirst src={linesBodyFirst} />
              <s.PhoneBodyFirst
                src={currLang === language.EN ? phoneBodyEng1 : phoneBodyRus1}
              />
            </s.ImageWrapper>
            <s.TasksAppWrapper>
              <s.Title2>{t("Ipoteko is a game for couples")}</s.Title2>
              <s.DescriptionApp>{t("First description")}</s.DescriptionApp>
              <s.TasksList>
                <Task url={medal}>{t("First step")}</Task>
                <Task url={moneyBag}>{t("Second step")}</Task>
                <Task url={starTask}>{t("Third step")}</Task>
              </s.TasksList>
            </s.TasksAppWrapper>
          </s.TasksDescription>

          <s.UpdateDescription>
            <s.TasksAppWrapper>
              <s.Title2>{t("Update title")}</s.Title2>
              <s.DescriptionApp>{t("Update description")}</s.DescriptionApp>
              <s.TasksList>
                <Task url={fire}>{t("First addition")}</Task>
                <Task url={okey}>{t("Second addition")}</Task>
                <Task url={heart}>{t("Third addition")}</Task>
              </s.TasksList>
            </s.TasksAppWrapper>

            <s.ImageWrapper>
              <s.LinesBodySecond src={linesBodySecond} />
              <s.PhoneBodySecond
                src={currLang === language.EN ? phoneBodyEng2 : phoneBodyRus2}
              />
            </s.ImageWrapper>
          </s.UpdateDescription>
        </s.BodyWrapper>
        <s.BigTasksWrapper>
          <BigTask
            url={boxingGloves}
            color="rgba(180, 61, 236, 0.6)"
            title={t("First goal title")}
          >
            {t("First goal description")}
          </BigTask>
          <BigTask
            url={coins}
            color="rgba(31, 182, 255, 0.6)"
            title={t("Second goal title")}
          >
            {t("Second goal description")}
          </BigTask>
          <BigTask
            url={starBigTask}
            color="rgba(255, 217, 73, 0.6)"
            title={t("Third goal title")}
          >
            {t("Third goal description")}
          </BigTask>
        </s.BigTasksWrapper>

        <s.LogoWithMotto>
          <s.LogoAppImage src={logoApp} />
          <s.MottoApp>{t("Footer title")} </s.MottoApp>
          <s.ButtonsDownloadFotter>
            <ButtonsDownload column={true} />
          </s.ButtonsDownloadFotter>
        </s.LogoWithMotto>

        <s.Footer>
          <s.DateWithNameApp>2020 © Ipoteko</s.DateWithNameApp>
          <s.PrivacyPolicy>{t("Privacy Policy")}</s.PrivacyPolicy>
        </s.Footer>
      </s.MainContainer>
    </s.MainWrapper>
  );
};

export default MainPage;
