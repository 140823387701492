import styled from "styled-components";

export const ButtonsDownload = styled.div`
  display: flex;
  & :first-child {
    margin-right: 16px;
  }
  @media ${(props) => props.theme.mediaQueries.smallest} {
    ${(props) =>
      props.column &&
      ` flex-direction: column;
    & :first-child {
    margin-right: 0px;
  }`}
  }
`;

export const ButtonDownloadWrapper = styled.div`
  color: #ffffff;
  cursor: pointer;
  display: flex;
  background: #010101;
  border-radius: 8px;
  height: 56px;
  padding: 0 10px;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    ${(props) =>
      !props.column
        ? `
    margin-top: 0px;
    padding: 0 8px;
    height: 56px;
    max-width: 160px;
    align-items: center;
    `
        : `width: 210px;
        height: 70px;
        margin-top: 16px;
`}
  }
`;

export const LogoPlatforn = styled.img`
  margin-right: 10px !important;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    ${(props) => (!props.column ? `height: 70%; margin-left: 5px; ` : `width: 41px`)}
  }
`;
export const DownloadTextWrapper = styled.div``;
export const DownloadText = styled.div`
  margin-top: 7px;
  @media ${(props) => props.theme.mediaQueries.smallest} {
    ${(props) => !props.column ? `font-size: 8px; margin-bottom: 6px; margin-top: 1px; margin-right: 0px !important;` : `font-size: 12px`}
  }
`;
export const DownloadPlatform = styled.img`
  @media ${(props) => props.theme.mediaQueries.smallest} {
    ${(props) => (!props.column ? `width: 100%` : `width: 140px; margin-top: 4px;`)}
  }
`;
